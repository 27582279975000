import React, {FC} from 'react';
import {useDevice, Box} from '@lookiero/aurora';
import {Image} from '@/components/atoms';
import {styles} from './PaymentCard.styles';

interface PaymentCardProps {
  card: string;
  id: number;
}

const PaymentCard: FC<PaymentCardProps> = ({card, id}) => {
  const {screen} = useDevice();
  const devicePath = screen.S ? 'mobile' : 'desktop';
  const imageURL = `${process.env.REACT_APP_ASSETS_PATH}/images/payment/${devicePath}/${card}.svg`;
  return (
    <Box key={id} style={styles.card}>
      <Image
        height={32}
        width={48}
        src={{
          default: imageURL,
        }}
        lazyLoad
      />
    </Box>
  );
};

export {PaymentCard};
