import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  links: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    gap: '$space6',
  },
  link: {
    width: 24,
    textDecoration: 'none',
    border: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
