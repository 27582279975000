import {Country} from '@/types/Country';

enum Platform {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  PAYPAL = 'paypal',
  AMERICAN_EXPRESS = 'american',
  CARTES = 'cartes',
}

enum Default {
  DEFAULT = 'DEFAULT',
}

export const PAYMENT_MAP: Partial<Record<Country | Default, Partial<Platform>[]>> = {
  DEFAULT: [Platform.VISA, Platform.MASTERCARD, Platform.PAYPAL, Platform.AMERICAN_EXPRESS],
  ES: [Platform.VISA, Platform.MASTERCARD, Platform.PAYPAL, Platform.AMERICAN_EXPRESS],
  FR: [Platform.VISA, Platform.MASTERCARD, Platform.PAYPAL, Platform.AMERICAN_EXPRESS, Platform.CARTES],
  IT: [Platform.VISA, Platform.MASTERCARD, Platform.PAYPAL, Platform.AMERICAN_EXPRESS],
  GB: [Platform.VISA, Platform.MASTERCARD, Platform.PAYPAL, Platform.AMERICAN_EXPRESS],
};

export const IDS = {
  SECTION: 'footer-payment-methods',
};
