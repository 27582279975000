import React from 'react';

import {COLOR, SPACE, Text, View, ViewProperties} from '@lookiero/aurora';
import {useIntl} from '@/modules/I18N';
import {getSiteConfig} from '@/modules/Site';
import {PAYMENT_MAP} from './PaymentMethods.definition';

import {styles} from './PaymentMethods.styles';
import {PaymentCard} from '../PaymentCard/PaymentCard';

const PaymentMethods: React.FC<ViewProperties> = viewProps => {
  const {country} = getSiteConfig();
  const {formatMessage} = useIntl();
  const cardList = (PAYMENT_MAP[country] as string[]) || PAYMENT_MAP.DEFAULT;

  const renderPaymentCards = (cards: string[]) =>
    cards.map((card, index) => <PaymentCard card={card} id={index} key={index} />);

  return (
    <View {...viewProps}>
      <Text as="p" detail level={1} marginTop={{L: undefined, default: SPACE.SPACE_8}} color={COLOR.TEXT_LIGHT}>
        {formatMessage({id: 'home.weaccept', defaultMessage: 'Aceptamos'})}
      </Text>
      <View nativeID="payment_images" style={styles.cards} marginTop={SPACE.SPACE_4}>
        {renderPaymentCards(cardList)}
      </View>
    </View>
  );
};

export {PaymentMethods};
