import React from 'react';
import {ALIGN, Box, COLOR, FLEX_DIRECTION, Layout, SPACE, Text, useDevice, View} from '@lookiero/aurora';
import {FollowUs, PaymentMethods} from './components';

import {IDS, TEXT} from './Footer.definition';
import {styles} from './Footer.styles';
import {Logo, LogoVariant} from '@/components/primitives/Logo';
import {useIntl} from '@/modules/I18N';

const Footer: React.FC = () => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();
  return (
    <Layout fullWidth={screen.L} style={styles.container} as="footer">
      <Box
        size={{L: '2/3'}}
        style={styles.footer}
        nativeID={IDS.SECTION}
        align={ALIGN.CENTER}
        marginVertical={screen.L ? SPACE.SPACE_12 : SPACE.SPACE_8}
      >
        <View flexDirection={screen.L ? FLEX_DIRECTION.ROW : FLEX_DIRECTION.COLUMN} style={styles.leftContainer}>
          <View flexDirection={FLEX_DIRECTION.COLUMN} style={{flex: 1}}>
            <Logo variant={LogoVariant.DARK} />
            <Text heading level={2} marginTop={SPACE.SPACE_4} color={COLOR.TEXT_LIGHT}>
              {formatMessage(TEXT.CLAIM)}
            </Text>
          </View>
          <FollowUs />
        </View>
        <View flexDirection={screen.L ? FLEX_DIRECTION.ROW : FLEX_DIRECTION.COLUMN}>
          <PaymentMethods style={{flex: 1}} />
          <View style={{flexDirection: 'column', justifyContent: 'flex-end'}}>
            <Text
              detail
              level={3}
              marginTop={{L: undefined, default: SPACE.SPACE_8}}
              style={{display: 'flex', flex: 1, flexDirection: 'column-reverse'}}
              color={COLOR.TEXT_LIGHT}
            >
              {formatMessage(TEXT.COPYRIGHT, {values: {year: String(new Date().getFullYear())}})}
            </Text>
          </View>
        </View>
      </Box>
    </Layout>
  );
};

export default Footer;
