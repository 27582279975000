import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: '$colorBgPrimary',
  },
  leftContainer: {
    gap: '$space16',
  },
  footer: {
    maxWidth: 960,
  },
});
