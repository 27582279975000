export const IDS = {
  SECTION: 'footer-social',
} as const;

export const SOCIAL_LINKS_CONFIG = [
  {
    name: 'facebook',
    url: 'social.facebook.url',
  },
  {
    name: 'instagram',
    url: 'social.instagram.url',
  },
  {
    name: 'tiktok',
    url: 'social.tiktok.url',
  },
  {
    name: 'pinterest',
    url: 'social.pinterest.url',
  },
];
