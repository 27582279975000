import React from 'react';
import {Pressable} from 'react-native';

import {View, useDevice} from '@lookiero/aurora';

import {useIntl} from '@/modules/I18N';
import {Image} from '@/components/atoms';

import {IDS, SOCIAL_LINKS_CONFIG} from './FollowUs.definition';
import {styles} from './FollowUs.styles';

const FollowUs: React.FC = () => {
  const {formatMessage} = useIntl();
  const {screen} = useDevice();
  const devicePath = screen.S ? 'mobile' : 'desktop';
  const imageURL = `${process.env.REACT_APP_ASSETS_PATH}/images/social/${devicePath}`;

  return (
    <View as="nav" style={styles.links} nativeID={IDS.SECTION}>
      {SOCIAL_LINKS_CONFIG.map(({name, url}) => {
        const urlSocialLink = formatMessage({id: url});
        const hasSocialLink = urlSocialLink !== url;

        return (
          hasSocialLink && (
            <Pressable key={name} onPress={() => window.open(urlSocialLink, '_blank')} style={styles.link}>
              <Image
                height={24}
                width={24}
                src={{
                  default: `${imageURL}/social-${name}${screen.S ? '' : '@2x'}.png`,
                }}
                lazyLoad
              />
            </Pressable>
          )
        );
      })}
    </View>
  );
};

export {FollowUs};
